
import { defineComponent, PropType } from 'vue'
import { useRouter } from 'vue-router'
import { AccessTemplate } from '@/modules/techdemo/model/access-template.model'
import { TechdemoRoute } from '@/modules/techdemo/routes'

export default defineComponent({
  name: 'AccessTemplatesTable',
  props: {
    accessTemplates: {
      type: Array as PropType<AccessTemplate[]>,
      required: true,
    },
  },
  setup() {
    const router = useRouter()

    const navigateToAccessTemplate = (id: string) => {
      router.push({ name: TechdemoRoute.EditAccessTemplate, params: { id } })
    }

    return { navigateToAccessTemplate }
  },
})
