import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DataLoader = _resolveComponent("DataLoader")!
  const _component_DataLoadingError = _resolveComponent("DataLoadingError")!
  const _component_AccessTemplateEditor = _resolveComponent("AccessTemplateEditor")!
  const _component_Panel = _resolveComponent("Panel")!

  return (_openBlock(), _createBlock(_component_Panel, null, {
    header: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.title), 1)
    ]),
    content: _withCtx(() => [
      _createVNode(_component_DataLoader, {
        "is-loading": _ctx.isLoading(_ctx.data.accessTemplate)
      }, null, 8, ["is-loading"]),
      _createVNode(_component_DataLoadingError, {
        subject: _ctx.data.accessTemplate
      }, null, 8, ["subject"]),
      (_ctx.isLoaded(_ctx.data.accessTemplate))
        ? (_openBlock(), _createBlock(_component_AccessTemplateEditor, {
            key: 0,
            "access-template": _ctx.data.accessTemplate.data,
            "onUpdate:access-template": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.data.accessTemplate.data) = $event)),
            "is-new": _ctx.data.isNewTemplate
          }, null, 8, ["access-template", "is-new"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}