
import { computed, defineComponent, reactive, watch } from 'vue'
import { errored, isLoaded, loaded, LOADING_STATE, LoadingState, isErrored, isLoading } from '@/utils/loading-state'
import DataLoader from '@/components/UI/DataLoader.vue'
import DataLoadingError from '@/components/UI/DataLoadingError.vue'
import { getAccessTemplate } from '@/modules/techdemo/api/access-templates.api'
import { AccessTemplate } from '@/modules/techdemo/model/access-template.model'
import { RouteRecordName, useRoute } from 'vue-router'
import AccessTemplateEditor from '@/modules/techdemo/components/AccessTemplateEditor.vue'
import Panel from '@/components/UI/Panel.vue'
import { TechdemoRoute } from '@/modules/techdemo/routes'

export default defineComponent({
  name: 'AccessTemplate',
  components: { Panel, AccessTemplateEditor, DataLoadingError, DataLoader },
  setup() {
    const data = reactive({
      accessTemplate: LOADING_STATE as LoadingState<AccessTemplate>,
      isNewTemplate: false,
    })

    const route = useRoute()

    const title = computed(() => {
      if (route.name === TechdemoRoute.CreateAccessTemplate) {
        return 'Create new access template'
      } else if (isLoaded(data.accessTemplate)) {
        return 'Access template: ' + data.accessTemplate.data.name
      } else {
        return 'Access template details'
      }
    })

    const getClonedTemplate = async (id: string) => {
      const template = await getAccessTemplate(id)

      return {
        ...template,
        _id: undefined,
        _rev: undefined,
        name: '',
      }
    }

    const loadTemplate = async (id: string | string[]) => {
      try {
        if (route.name === TechdemoRoute.CreateAccessTemplate) {
          data.accessTemplate = loaded(id ? await getClonedTemplate(String(id)) : new AccessTemplate({}))
          data.isNewTemplate = true
        } else {
          data.accessTemplate = loaded(await getAccessTemplate(String(id)))
          data.isNewTemplate = false
        }
      } catch (e) {
        data.accessTemplate = errored(e)
      }
    }

    type WatchT = [string | string[], RouteRecordName | null | undefined]
    watch(
      (): WatchT => [route.params.id, route.name],
      ([id, name]: WatchT) => {
        if (name === TechdemoRoute.CreateAccessTemplate || name === TechdemoRoute.EditAccessTemplate) {
          loadTemplate(id)
        }
      }
    )

    loadTemplate(route.params.id)

    return {
      data,
      title,
      isLoaded,
      isLoading,
      isErrored,
    }
  },
})
