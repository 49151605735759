
import { defineComponent, onMounted, reactive } from 'vue'
import { errored, isLoaded, loaded, LOADING_STATE, LoadingState, isErrored, isLoading } from '@/utils/loading-state'
import AccessTemplatesTable from '@/modules/techdemo/components/AccessTemplatesTable.vue'
import DataLoader from '@/components/UI/DataLoader.vue'
import DataLoadingError from '@/components/UI/DataLoadingError.vue'
import { getAllAccessTemplates } from '@/modules/techdemo/api/access-templates.api'
import { AccessTemplate } from '@/modules/techdemo/model/access-template.model'
import { useRouter } from 'vue-router'
import Panel from '@/components/UI/Panel.vue'
import { TechdemoRoute } from '@/modules/techdemo/routes'

export default defineComponent({
  name: 'AccessTemplates',
  components: { Panel, DataLoadingError, DataLoader, AccessTemplatesTable },
  setup() {
    const data = reactive({
      accessTemplates: LOADING_STATE as LoadingState<AccessTemplate[]>,
    })

    const router = useRouter()

    const goToTemplateCreator = () => {
      router.push({ name: TechdemoRoute.CreateAccessTemplate })
    }

    onMounted(async () => {
      try {
        data.accessTemplates = loaded(await getAllAccessTemplates())
      } catch (e) {
        data.accessTemplates = errored(e)
      }
    })

    return {
      data,
      isLoaded,
      isLoading,
      isErrored,
      goToTemplateCreator,
    }
  },
})
