import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormStateAlert = _resolveComponent("FormStateAlert")!
  const _component_DataLoader = _resolveComponent("DataLoader")!
  const _component_DataLoadingError = _resolveComponent("DataLoadingError")!
  const _component_PanelSubheading = _resolveComponent("PanelSubheading")!
  const _component_va_input = _resolveComponent("va-input")!
  const _component_HiddenAccessItemsNotice = _resolveComponent("HiddenAccessItemsNotice")!
  const _component_AccessEditor = _resolveComponent("AccessEditor")!
  const _component_va_button = _resolveComponent("va-button")!
  const _component_va_form = _resolveComponent("va-form")!
  const _component_va_modal = _resolveComponent("va-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_FormStateAlert, {
      "form-state": _ctx.data.formState
    }, null, 8, ["form-state"]),
    _createVNode(_component_DataLoader, { "is-loading": _ctx.isAnyLoading }, null, 8, ["is-loading"]),
    _createVNode(_component_DataLoadingError, {
      name: "AccessMetadata",
      subject: _ctx.data.accessMetadata
    }, null, 8, ["subject"]),
    _createVNode(_component_DataLoadingError, {
      name: "AccessTemplates",
      subject: _ctx.data.accessTemplates
    }, null, 8, ["subject"]),
    (!_ctx.isAnyLoading && !_ctx.isAnyErrored)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_va_form, { class: "mb-5" }, {
            default: _withCtx(() => [
              _createVNode(_component_PanelSubheading, { class: "mb-3" }, {
                default: _withCtx(() => [
                  _createTextVNode("General settings")
                ]),
                _: 1
              }),
              _createVNode(_component_va_input, {
                bordered: "",
                class: "mb-3",
                label: "Name",
                modelValue: _ctx.localAccessTemplate.name,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localAccessTemplate.name) = $event))
              }, null, 8, ["modelValue"]),
              _createVNode(_component_PanelSubheading, { class: "mt-1 mb-3" }, {
                default: _withCtx(() => [
                  _createTextVNode("Available demos")
                ]),
                _: 1
              }),
              _createVNode(_component_HiddenAccessItemsNotice),
              _createVNode(_component_AccessEditor, {
                class: "mb-3",
                modelValue: _ctx.localAccessTemplate.access,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.localAccessTemplate.access) = $event)),
                "access-metadata": _ctx.data.accessMetadata.data
              }, null, 8, ["modelValue", "access-metadata"]),
              (!_ctx.isNew)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createVNode(_component_va_button, {
                      class: "mr-2",
                      onClick: _cache[2] || (_cache[2] = () => _ctx.actionHandler(_ctx.saveTemplateHandler))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Save")
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_va_button, {
                      class: "mr-2",
                      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.data.showDeleteModal = true)),
                      color: "danger"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Delete")
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_va_button, {
                      class: "mr-2",
                      onClick: _ctx.cloneTemplate,
                      color: "success"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Clone this template")
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ], 64))
                : _createCommentVNode("", true),
              (_ctx.isNew)
                ? (_openBlock(), _createBlock(_component_va_button, {
                    key: 1,
                    class: "mr-2",
                    onClick: _cache[4] || (_cache[4] = () => _ctx.actionHandler(_ctx.createTemplateHandler)),
                    color: "success"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Create")
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          _createVNode(_component_va_modal, {
            modelValue: _ctx.data.showDeleteModal,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.data.showDeleteModal) = $event)),
            title: "Confirm",
            message: "Are you sure you want to delete this template?",
            onOk: _cache[6] || (_cache[6] = () => _ctx.actionHandler(_ctx.deleteTemplateHandler))
          }, null, 8, ["modelValue"])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}