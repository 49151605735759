
import { computed, defineComponent, PropType, reactive, watch } from 'vue'
import { errored, loaded, isLoaded, LOADING_STATE, LoadingState, isLoading, isErrored } from '@/utils/loading-state'
import { getAccessMetadata } from '@/modules/techdemo/api/metadata.api'
import AccessEditor from '@/modules/techdemo/components/AccessEditor.vue'
import { AccessMetadata, extendAccessMetadataWithUserAccessData } from '@/modules/techdemo/model/access.model'
import { AccessTemplate, validateAccessTemplate } from '@/modules/techdemo/model/access-template.model'
import {
  createAccessTemplate,
  deleteAccessTemplate,
  getAllAccessTemplates,
  updateAccessTemplate,
} from '@/modules/techdemo/api/access-templates.api'
import {
  FORM_INITIAL_STATE,
  FORM_LOADING_STATE,
  formError,
  FormState,
  formSuccess,
  isFormErrored,
  isFormLoading,
  isFormSuccess,
} from '@/utils/form-state'
import FormStateAlert from '@/components/UI/FormStateAlert.vue'
import { useRouter } from 'vue-router'
import PanelSubheading from '@/components/UI/PanelSubheading.vue'
import DataLoader from '@/components/UI/DataLoader.vue'
import DataLoadingError from '@/components/UI/DataLoadingError.vue'
import { TechdemoRoute } from '@/modules/techdemo/routes'
import HiddenAccessItemsNotice from '@/components/UI/HiddenAccessItemsNotice.vue'
import { ApiError } from '@/api/_shared'
import { useToaster } from '@/composables/useToaster'

export default defineComponent({
  name: 'AccessTemplateEditor',
  components: { HiddenAccessItemsNotice, DataLoadingError, DataLoader, PanelSubheading, FormStateAlert, AccessEditor },
  props: {
    accessTemplate: {
      type: Object as PropType<AccessTemplate>,
      required: true,
    },
    isNew: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:accessTemplate'],
  setup(props, { emit }) {
    const data = reactive({
      formState: FORM_INITIAL_STATE as FormState,
      accessMetadata: LOADING_STATE as LoadingState<AccessMetadata>,
      accessTemplates: LOADING_STATE as LoadingState<AccessTemplate[]>,
      showDeleteModal: false,
    })

    const localAccessTemplate = computed({
      get: () => props.accessTemplate,
      set: (value: AccessTemplate) => emit('update:accessTemplate', value),
    })

    const router = useRouter()
    const toaster = useToaster()

    const isAnyLoading = computed(() => isLoading(data.accessMetadata) || isLoading(data.accessTemplates))
    const isAnyErrored = computed(() => isErrored(data.accessMetadata) || isErrored(data.accessTemplates))

    watch(
      () => props.accessTemplate,
      () => {
        window.scrollTo({ left: 0, top: 0 })
      }
    )

    const fetchAccessMetadata = async () => {
      try {
        data.accessMetadata = LOADING_STATE

        let accessMetadata = await getAccessMetadata()
        accessMetadata = extendAccessMetadataWithUserAccessData(accessMetadata, props.accessTemplate.access)

        data.accessMetadata = loaded(accessMetadata)
      } catch (e) {
        data.accessMetadata = errored(e)
      }
    }

    const fetchAccessTemplates = async () => {
      try {
        data.accessTemplates = LOADING_STATE
        data.accessTemplates = loaded(await getAllAccessTemplates())
      } catch (e) {
        data.accessTemplates = errored(e)
      }
    }

    const actionHandler = async (actionFn: () => void) => {
      if (data.formState === FORM_LOADING_STATE) {
        return
      }

      window.scrollTo({ left: 0, top: 0 })

      data.formState = FORM_LOADING_STATE

      return actionFn()
    }

    const saveTemplateHandler = async () => {
      try {
        const accessTemplateValidation = validateAccessTemplate(localAccessTemplate.value)

        if (accessTemplateValidation !== true) {
          data.formState = formError('The form contains errors:', accessTemplateValidation)
          return
        }

        localAccessTemplate.value = await updateAccessTemplate(localAccessTemplate.value)
        data.formState = formSuccess(`Template "${localAccessTemplate.value.name}" has been successfully updated.`)
      } catch (e) {
        data.formState = formError(
          `Template "${localAccessTemplate.value.name}" could not be updated. ${e}`,
          (e as ApiError)?.errors
        )
      }
    }

    const deleteTemplateHandler = async () => {
      try {
        await deleteAccessTemplate(localAccessTemplate.value)

        const message = `Template "${localAccessTemplate.value.name}" has been successfully deleted.`
        data.formState = formSuccess(message)
        toaster && toaster.success(message)

        await router.push({ name: TechdemoRoute.AccessTemplates })
      } catch (e) {
        data.formState = formError(
          `Template "${localAccessTemplate.value.name}" could not be deleted. ${e}`,
          (e as ApiError)?.errors
        )
      }
    }

    const createTemplateHandler = async () => {
      try {
        const accessTemplateValidation = validateAccessTemplate(localAccessTemplate.value)

        if (accessTemplateValidation !== true) {
          data.formState = formError('The form contains errors:', accessTemplateValidation)
          return
        }

        const newTemplate = await createAccessTemplate(localAccessTemplate.value)
        localAccessTemplate.value = newTemplate

        const message = `Template "${localAccessTemplate.value.name}" has been successfully created.`
        data.formState = formSuccess(message)
        toaster && toaster.success(message)

        await router.push({ name: TechdemoRoute.EditAccessTemplate, params: { id: newTemplate._id } })
      } catch (e) {
        data.formState = formError(
          `Template "${localAccessTemplate.value.name}" could not be created. ${e}`,
          (e as ApiError)?.errors
        )
      }
    }

    const cloneTemplate = () => {
      router.push({ name: TechdemoRoute.CreateAccessTemplate, params: { id: localAccessTemplate.value._id } })
    }

    fetchAccessMetadata()
    fetchAccessTemplates()

    return {
      data,
      localAccessTemplate,
      actionHandler,
      saveTemplateHandler,
      deleteTemplateHandler,
      createTemplateHandler,
      cloneTemplate,
      isAnyLoading,
      isAnyErrored,
      isLoaded,
      isFormLoading,
      isFormErrored,
      isFormSuccess,
      toaster,
    }
  },
})
